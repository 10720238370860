import axios from 'axios'
export default {
    state: {
        menu: [],
        showMenu: false,
    },
    mutations: {
        setMenu(state, payload) {
            state.menu = payload
        },
        setShowMenu(state, payload) {
            state.showMenu = payload
        },
    },
    actions: {
        setMenu(context, payload) {
            context.commit('setMenu', payload)
        },
        setShowMenu(context, payload) {
            context.commit('setShowMenu', payload)
        },
        toogleMenu(context) {
            context.commit('setShowMenu', !context.state.showMenu)
        },
        loadMenu(context, payload) {
            axios(`userMenu/user`).then((resp) => {
                if(resp.status < 400){
                    if(typeof payload.cb == 'function'){
                        payload.cb(resp.data[0])
                    }
                    context.commit('setMenu', resp.data)
                }
            });
        },
    },
    getters: {
        getMenu(state) {
            return state.menu
        },
        getShowMenu(state) {
            return state.showMenu
        },
    },
}