import axios from 'axios'

export default {
  state: {
    moradores: [],
    morador: {},
    aptoPublic: {},
    msgCadMorador: {},
  },
  mutations: {
    setMoradores(state, payload) {
      state.moradores = payload
    },
    setMorador(state, payload) {
      state.cliente = payload
    },
    aptoPublic(state, payload) {
      state.aptoPublic = payload
    },
    setMsgCadMorador(state, payload) {
      state.msgCadMorador = payload
    }
  },
  actions: {
    setMoradores(context, payload) {
      context.commit('setMoradores', payload)
    },
    setMorador(context, payload) {
      context.commit('setMorador', payload)
    },
    setAptoPublic(context, payload) {
      context.commit('aptoPublic', payload)
    },
    saveMorador(context, payload) {
      axios.postForm('moradores/public', payload).then((resp) => {
        if (resp.status < 400) {
          context.commit('setMsgCadMorador', { text: 'Morador cadastrado com sucesso!', value: 0 })
        } else {
          context.commit('setMsgCadMorador', { text: 'Erro ao cadastrar morador!', value: 0 })
        }
      })
      // axios({
      //   method: 'post',
      //   url: 'moradores/public',
      //   data: payload,
      // })
      //   .then((resp) => {
      //     if (resp.status < 400) {
      //       if (payload.picture) {
      //         const formData = new FormData()
      //         formData.append('picture', payload.picture)
      //         axios({
      //           method: 'put',
      //           url: `moradores/public/${resp.data.id}`,
      //           data: formData,
      //           headers: {
      //             'Content-Type': 'multipart/form-data',
      //           },
      //         })
      //       }
      //       context.commit('setMsgCadMorador', { text: 'Morador cadastrado com sucesso!', value: 0 })
      //     } else {
      //       context.commit('setMsgCadMorador', { text: 'Erro ao cadastrar morador!', value: 0 })
      //     }
      //   })
    },
    updateMorador(context, payload) {
      axios.put(`moradores/${payload.id}`, payload).then((resp) => {
        if (resp.status < 400) {
          context.dispatch('showSuccessSnack', 'Morador atualizado com sucesso!')
          context.dispatch('loadMoradores', payload.clienteId)
        } else {
          context.dispatch('showErrorSnack', 'Erro ao atualizar morador!')
        }
      })
    },
    loadMoradores(context, payload) {
      axios({
        method: 'get',
        url: `moradores?clienteId=${payload}`,
      })
        .then((resp) => {
          if (resp.status < 400) {
            context.commit('setMoradores', resp.data)
          }
        })
    },
    loadAptoPublic(context, payload) {
      axios({
        method: 'get',
        url: `moradores/public?apto=${payload.apto}&clienteId=${payload.clienteId}`,
      })
        .then((resp) => {
          if (resp.status < 400) {
            context.commit('setMoradores', resp.data)
          }
        })
    },
    loadMoradoresForConfirmations(context, payload) {
      axios({
        method: 'post',
        url: `moradores/confirmation`,
        data: payload,
      })
        .then((resp) => {
          if (resp.status < 400) {
            context.commit('setMoradores', resp.data);
            if(payload.callback && typeof payload.callback == 'function') {
              payload.callback(payload.args);
            }
          }
        })
    },
  },
  getters: {
    getMoradores(state) {
      return state.moradores
    },
    getMorador(state) {
      return state.morador
    },
    getAptoPublic(state) {
      return state.aptoPublic
    },
    getMsgCadMorador(state) {
      return state.msgCadMorador
    },
  },
}