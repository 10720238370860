import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import axios from './plugins/axios'

loadFonts()

const primaryColor = '#005f36'
const secundaryColor = '#ef9b11'
const tertiaryColor = '#478566'

vuetify.theme.global.current.value.colors.primary = primaryColor
vuetify.theme.global.current.value.colors.secondary = secundaryColor
vuetify.theme.global.current.value.colors.tertiary = tertiaryColor


const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)

app.config.globalProperties.$http = axios
  
app.mount('#app')
